import React, { Fragment } from "react";
import NavBar from "./NavBar";
import Header from "./Header";
import Particles from "react-particles-js";
import { makeStyles } from "@material-ui/core/styles";

/**
 * @author
 * @function Home
 **/
//CSS STYLES
const useStyles = makeStyles((theme) => ({
  particleCanvas: {
    position: "absolute",
    opacity: "0.5",
  },
}));
const Home = (props) => {
  const classes = useStyles();
  return (
    <Fragment>
      <NavBar />
      <Header />
      <Particles
        canvasClassName={classes.particleCanvas}
        params={{
          particles: {
            number: { value: 80, density: { enable: true, value_area: 600 } },
            shape: { type: "circle", stroke: { width: 1, color: "black" } },
            size: {
              value: 8,
              random: true,
              anim: { enable: true, speed: 6, size_min: 0.1, sync: true },
            },
            opacity: {
              value: 1,
              random: true,
              anim: { enable: true, opacity: 1, opacity_min: 0.1 },
              sync: false,
            },
          },
        }}
      />
    </Fragment>
  );
};

export default Home;
