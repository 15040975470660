import React, { Fragment } from "react";
import NavBar from "./NavBar";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Box } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import { red } from "@material-ui/core/colors";
import demo1 from "../images/demo1.png";
import demo2 from "../images/demo2.png";
import demo3 from "../images/demo3.png";
import demo4 from "../images/demo4.png";

/**
 * @author
 * @function Portfolio
 **/
const useStyles = makeStyles((theme) => ({
  color1: {
    color: "#FFEB3B",
  },
  color2: {
    color: "#3f51b5",
  },
  layerbg: {
    background: "#efefef",
  },
  root: {
    maxWidth: 345,
    marginBottom: 30,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const Portfolio = (props) => {
  const classes = useStyles();

  return (
    <Fragment>
      <NavBar />
      <Box component="header">
        <Grid container className={classes.layerbg}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center" className={classes.color2}>
              Portfolio
            </Typography>
          </Grid>
          <Box className="container">
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Card className={classes.root}>
                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe" className={classes.avatar}>
                        R
                      </Avatar>
                    }
                    title="Roommate Finder"
                    subheader="September 14, 2016"
                  />
                  <CardMedia
                    className={classes.media}
                    image={demo1}
                    title="Paella dish"
                  />
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      This was a project where user can find Room or Roommate
                      according to their choices.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card className={classes.root}>
                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe" className={classes.avatar}>
                        R
                      </Avatar>
                    }
                    title="Raasforce"
                    subheader="January 10, 2016"
                  />
                  <CardMedia
                    className={classes.media}
                    image={demo2}
                    title="Raasforce"
                  />
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      This was a project of Job Portal where Employer can post
                      jobs, Employer and Agency can hire employee, even referrar
                      can get referral bonus.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card className={classes.root}>
                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe" className={classes.avatar}>
                        R
                      </Avatar>
                    }
                    title="Vanilla IT"
                    subheader="February 10, 2016"
                  />
                  <CardMedia
                    className={classes.media}
                    image={demo3}
                    title="Vanilla IT"
                  />
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      This was a website for an IT Industry called Vanilla.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card className={classes.root}>
                  <CardHeader
                    avatar={
                      <Avatar aria-label="recipe" className={classes.avatar}>
                        R
                      </Avatar>
                    }
                    title="Inventory System"
                    subheader="March 13, 2016"
                  />
                  <CardMedia
                    className={classes.media}
                    image={demo4}
                    title="Inventory System"
                  />
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      This was a project of Inventory System where User can
                      entry products for his inventory and he can sell Item
                      through POS and Accounting system.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default Portfolio;
