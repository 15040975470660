import React, { Fragment } from "react";
import { Route, Switch, Link } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import Home from "./components/Index";
import Projects from "./components/Projects";
import Portfolio from "./components/Portfolio";
import Contact from "./components/Contact";

function App() {
  return (
    <CssBaseline>
      <Fragment>
        <Route exact path="/" component={Home}></Route>
        <Route path="/Projects" component={Projects}></Route>
        <Route path="/Portfolio" component={Portfolio}></Route>
        <Route path="/Contact" component={Contact}></Route>
      </Fragment>
    </CssBaseline>
  );
}

export default App;
