import React, { Fragment } from "react";
import NavBar from "./NavBar";
import { Typography, Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";
import Email from "@material-ui/icons/DraftsOutlined";
import PhoneIcon from "@material-ui/icons/Phone";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LanguageIcon from "@material-ui/icons/Language";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
/**
 * @author
 * @function Contact
 **/
const useStyles = makeStyles((theme) => ({
  color1: {
    color: "#FFEB3B",
  },
  color2: {
    color: "#3f51b5",
  },
  layerbg: {
    background: "#efefef",
  },
}));

const Contact = (props) => {
  const classes = useStyles();
  return (
    <Fragment>
      <NavBar />
      <Box component="header">
        <Grid container className={classes.layerbg}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center" className={classes.color2}>
              Contact
            </Typography>
          </Grid>
          <Box className="container">
            <Grid container alignItems="center" justify="center">
              <Grid item xs={6}>
                <Paper className="mb100">
                  <List className={classes.root}>
                    <ListItem>
                      <ListItemIcon>
                        <Email />
                      </ListItemIcon>
                      <ListItemText primary="rainmvc@gmail.com" />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                      <ListItemIcon>
                        <PhoneIcon />
                      </ListItemIcon>
                      <ListItemText primary="+8801670225611" />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                      <ListItemIcon>
                        <LocationOnIcon />
                      </ListItemIcon>
                      <ListItemText primary="Dhaka" />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                      <ListItemIcon>
                        <LanguageIcon />
                      </ListItemIcon>
                      <Link href="http://itsmerain.com" target="">
                        http://itsmerain.com
                      </Link>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                      <ListItemIcon>
                        <LinkedInIcon />
                      </ListItemIcon>
                      <Link href="http://linkedin.com/in/itsrain" target="">
                        linkedin.com/in/itsrain
                      </Link>
                    </ListItem>
                  </List>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default Contact;
