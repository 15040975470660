import React, { useState } from "react";
import { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import File from "@material-ui/icons/DescriptionOutlined";
import Email from "@material-ui/icons/DraftsOutlined";
import MobilRightMenu from "@material-ui/core/Drawer";
import { Link } from "react-router-dom";
import me from "../images/me.jpg";

import {
  AppBar,
  Toolbar,
  Box,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Divider,
  Typography,
  IconButton,
  ListItemIcon,
} from "@material-ui/core";

import { Home, Apple, Menu } from "@material-ui/icons";

import user from "../images/user.svg";

/**
 * @author RAIN
 * @function NavBar
 **/

//CSS STYLES
const useStyles = makeStyles((theme) => ({
  menuSideBar: {
    width: 250,
    height: "100vh",
    background: "#FFF",
    zIndex: "99",
  },
  avatar: {
    margin: "0px auto 20px auto",
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

const MenuList = [
  {
    menu: "Home",
    icon: <Home />,
    path: "/",
  },
  {
    menu: "Portfolio",
    icon: <Apple />,
    path: "/Portfolio",
  },
  {
    menu: "Projects",
    icon: <File />,
    path: "/Projects",
  },
  {
    menu: "Contact",
    icon: <Email />,
    path: "/Contact",
  },
];

const NavBar = (props) => {
  const [state, setState] = useState({
    right: false,
  });

  const toggleSlider = (slider, open) => () => {
    setState({ ...state, [slider]: open });
  };

  const classes = useStyles();
  const sideBar = (slider) => (
    <Box className={classes.menuSideBar} component="div">
      <Box className="gradient">
        <Avatar className={classes.avatar} src={me} alt="Imran" />
        <Divider />
        <List>
          {MenuList.map((list, key) => (
            <ListItem button key={key} component={Link} to={list.path}>
              <ListItemIcon>{list.icon}</ListItemIcon>
              <ListItemText>{list.menu}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
  return (
    <Fragment>
      <Box>
        <AppBar position="static">
          <Toolbar>
            <IconButton onClick={toggleSlider("left", true)}>
              <Menu style={{ color: "#fff" }} />
            </IconButton>
            <Typography variant="h6">Portfolio</Typography>
            <MobilRightMenu
              anchor="left"
              onClose={toggleSlider("left", false)}
              open={state.left}
            >
              {sideBar("left")}
            </MobilRightMenu>
          </Toolbar>
        </AppBar>
      </Box>
    </Fragment>
  );
};

export default NavBar;
