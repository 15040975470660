import React from "react";
import { Typography, Grid, Avatar, Box } from "@material-ui/core";
import AvatarPhoto from "../images/user.svg";
import Typed from "react-typed";
import { makeStyles } from "@material-ui/core/styles";
import me from "../images/me.jpg";
import classNames from "classnames";

/**
 * @author
 * @function Header
 **/
//CSS STYLES
const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  typedContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100vw",
    textAlign: "center",
    zIndex: 1,
  },
  color1: {
    color: "#FFEB3B",
  },
  color2: {
    color: "#3f51b5",
  },
  bannerText: {
    overflow: "hidden",
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const btnClass = classNames("typedContainer", "btn-primary");
  return (
    <Box className={classes.bannerText}>
      <Box className={classes.typedContainer}>
        <Grid container justify="center">
          <Avatar className={classes.avatar} src={me} alt="Me" />
        </Grid>
        <Typography variant="h4" className={classes.color2}>
          <Typed strings={["S. R. MOHAMMAD IMRAN"]} typeSpeed={40} />
        </Typography>
        <br />
        <Typography variant="h6" className={classes.color2}>
          <Typed
            strings={[
              "Back-End Developer",
              "Front-End Developer",
              "Web Designer",
            ]}
            typeSpeed={40}
            backSpeed={60}
            loop
          />
        </Typography>
      </Box>
    </Box>
  );
};

export default Header;
