import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Grid } from "@material-ui/core";
import NavBar from "./NavBar";
/**
 * @author
 * @function Projects
 **/
//CSS STYLES
const useStyles = makeStyles((theme) => ({
  color1: {
    color: "#FFEB3B",
  },
  color2: {
    color: "#3f51b5",
  },
  layerbg: {
    background: "#efefef",
  },
}));

const Projects = (props) => {
  const classes = useStyles();

  return (
    <Fragment>
      <NavBar />
      <Box component="header">
        <Grid container className={classes.layerbg}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center" className={classes.color2}>
              Working Experience
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Box className="timeline" component="div">
              <Box>
                <Box className="direction-r" component="div">
                  <Box className="flag-wrapper" component="div">
                    <Typography className="flag">CMS</Typography>
                    <Box className="time-wrapper">
                      <Typography className="time">
                        09/2015 – Present
                      </Typography>
                    </Box>
                  </Box>
                  <Box component="div" className="desc">
                    Centralized content management system for 20+ Content
                    Streaming websites which are connected with Telco's like
                    Local Telco: GrameenPhone, Robi, Airtel, Banglalink,
                    Teletalk. International Telco: Airtel (Srilanka), Dialog
                    (Srilanka), Ncell (Nepal), Ooredoo (Oman, Kuwait, Maldives),
                    Etisalat (UAE), DU (UAE), Zain (KSA), Mobily (KSA), NTC
                    (Nepal), SMART (Nepal), Telenor (Myanmar), Digi (Malaysia).
                    Role-based Intelligent Reporting platform. Telco's Customer
                    care facilities.
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box className="direction-l" component="div">
                  <Box className="flag-wrapper" component="div">
                    <Typography className="flag">CRUX</Typography>
                    <Box className="time-wrapper">
                      <Typography className="time">
                        09/2015 – Present
                      </Typography>
                    </Box>
                  </Box>
                  <Box component="div" className="desc">
                    Web API 2.0 Used HTTP Header validation. Encrypted secure
                    data with AES algorithm. Encryption CipherMode CBC
                    PaddingMode PKCS7 used also IV value. Backend language used
                    C# on MVC platform and Database used MSSQL. Integrated
                    Subscription, Unsubscription, Check Subscription, Charging
                    APIs with Telco's like Local Telco: GrameenPhone, Robi,
                    Airtel, Banglalink, Teletalk. International Telco: Airtel
                    (Srilanka), Dialog (Srilanka), Ncell (Nepal), Ooredoo (Oman,
                    Kuwait, Maldives), Etisalat (UAE), DU (UAE), Zain (KSA),
                    Mobily (KSA), NTC (Nepal), SMART (Nepal), Telenor (Myanmar),
                    Digi (Malaysia).
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box className="direction-r" component="div">
                  <Box className="flag-wrapper" component="div">
                    <Typography className="flag">CMS</Typography>
                    <Box className="time-wrapper">
                      <Typography className="time">
                        09/2015 – Present
                      </Typography>
                    </Box>
                  </Box>
                  <Box component="div" className="desc">
                    Centralized content management system for 20+ Content
                    Streaming websites which are connected with Telco's like
                    Local Telco: GrameenPhone, Robi, Airtel, Banglalink,
                    Teletalk. International Telco: Airtel (Srilanka), Dialog
                    (Srilanka), Ncell (Nepal), Ooredoo (Oman, Kuwait, Maldives),
                    Etisalat (UAE), DU (UAE), Zain (KSA), Mobily (KSA), NTC
                    (Nepal), SMART (Nepal), Telenor (Myanmar), Digi (Malaysia).
                    Role-based Intelligent Reporting platform. Telco's Customer
                    care facilities.
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box className="direction-l" component="div">
                  <Box className="flag-wrapper" component="div">
                    <Typography className="flag">CRUX</Typography>
                    <Box className="time-wrapper">
                      <Typography className="time">
                        09/2015 – Present
                      </Typography>
                    </Box>
                  </Box>
                  <Box component="div" className="desc">
                    Web API 2.0 Used HTTP Header validation. Encrypted secure
                    data with AES algorithm. Encryption CipherMode CBC
                    PaddingMode PKCS7 used also IV value. Backend language used
                    C# on MVC platform and Database used MSSQL. Integrated
                    Subscription, Unsubscription, Check Subscription, Charging
                    APIs with Telco's like Local Telco: GrameenPhone, Robi,
                    Airtel, Banglalink, Teletalk. International Telco: Airtel
                    (Srilanka), Dialog (Srilanka), Ncell (Nepal), Ooredoo (Oman,
                    Kuwait, Maldives), Etisalat (UAE), DU (UAE), Zain (KSA),
                    Mobily (KSA), NTC (Nepal), SMART (Nepal), Telenor (Myanmar),
                    Digi (Malaysia).
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default Projects;
